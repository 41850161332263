body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 16px;
  color: $gray4;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body * {
  font-family: $primary-fonts !important;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

i {
  font-style: normal;
  display: inline-block;
}

.wrapper,
body,
html {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;

  &.text-blue {
    color: #1da2ef;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  &.forgot-password-title {
    color: #3979ff;
  }

  font-size: 30px;
  line-height: 1.27;
  font-weight: 600;
  color: $gray1;

  @include bp(sm-max) {
    font-size: 28px;
  }
}

h2 {
  font-size: 24px;
  line-height: 1.34;
  font-weight: 500;
  color: $gray1;

  @include bp(sm-max) {
    font-size: 22px;
  }
}

h3 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 600;
  color: $gray2;

  @include bp(sm-max) {
    font-size: 18px;
  }
}

h4 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  color: $gray1;

  @include bp(sm-max) {
    font-size: 16px;
  }

  &.heading-onboarding-success {
    color: $gray4;
  }
}

h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: $gray1;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;

  &.additional-view-space {
    margin-bottom: 10px;
  }
}

.container {
  max-width: 1160px;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;

  @include bp(xxxs-min) {
    padding: 0 15px;
  }
}

.container-full {
  // max-width: 1160px;
  width: 100%;
  padding: 0 30px 0 20px;
  margin: 0 auto;

  @include bp(xxxs-min) {
    padding: 0 15px;
  }

  &.redirecting {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
}

.relative {
  position: relative;
}

.primary-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25;
  color: $orange1;
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: none !important;
  @include transition(all 0.3s);

  &:hover {
    opacity: 0.8;
  }

  &.blue-text {
    color: $primary-color;
  }
}

.secondary-link {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25;
  color: $red1;
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: underline !important;
  text-underline-offset: 2px;
  @include transition(all 0.3s);

  &:hover {
    text-decoration: none !important;
  }
}

.form-group {
  margin: 0 0 20px;

  .form-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42;
    color: $gray3;
    margin: 0 0 6px;
    display: block;

    &.label-size-title {
      font-weight: 500 !important;
    }
  }

  .MuiInputLabel-formControl {
    top: -5px;
  }

  .MuiTypography-caption {
    margin: 6px 0 0;
    font-size: 14px;
    line-height: 1.42;
    color: $red1;
    display: inline-block;
  }

  .MuiInputAdornment-root {
    display: inline-flex;
  }

  .MuiOutlinedInput-notchedOutline {
    span {
      width: 0;
      padding: 0;
    }
  }

  .MuiFormControl-root,
  .MuiStack-root {
    width: 100%;
    padding-top: 0;
  }

  p.form-control {
    color: $gray5;

    &.file {
      color: $blue3;

      &:hover {
        text-decoration: underline;
      }

      a {
        color: $blue3;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .form-control {
    width: 100%;

    &.textarea {
      .MuiInputBase-root {
        padding: 0;
        height: auto;
      }
    }

    .MuiInputBase-root {
      @include border-radius(8px);
      font-size: 16px;
      line-height: 1.5;
      font-family: $primary-fonts;
      color: $gray1;
      letter-spacing: 0;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      height: 44px;

      &::placeholder {
        color: $light-gray5;
      }

      &.Mui-disabled {
        background-color: $light-gray;
      }

      .MuiInputBase-input {
        padding: 10px 14px;
        -webkit-background-clip: text !important;
        background-clip: text !important;

        &:-webkit-autofill {
          border-radius: 0;
        }

        &[type='password'] {
          -webkit-appearance: none;
          appearance: none;
          -moz-appearance: none;

          &::-ms-reveal,
          &::-ms-clear {
            display: none;
          }
        }
      }

      .password-visiblity-btn {
        display: block;
      }

      .message-ic {
        display: none;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $light-gray4;
        @include box-shadow(0px, 1px, 2px, 0px, #1018280d);
      }

      &:hover,
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $light-gray4;
        }
      }

      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border-color: $red2;
        }

        .message-ic {
          display: flex;
          height: 100%;
          margin-right: -5px;
          color: $red1;

          svg {
            color: $red1;
          }
        }

        .password-visiblity-btn {
          display: none;
        }
      }

      .MuiInputAdornment-root {
        height: 100%;
        margin: 0 !important;

        svg {
          width: 16px;
          height: 16px;
          color: $light-gray5;
        }
      }
    }

    .MuiFormHelperText-root {
      margin: 6px 0 0;
      font-size: 14px;
      line-height: 1.42;

      &.Mui-error {
        color: $red1;
      }
    }
  }

  &.file-upload {
    .form-control {
      .MuiInputBase-root {
        height: 150px;

        input[type='file'] {
          opacity: 0;
          height: 100%;
          padding: 0 14px;
          z-index: 1;
          cursor: pointer;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
        }

        .MuiInputBase-input {
          display: none;
        }
      }
    }

    .preview-uploaded {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 15px;
      height: 100%;

      img {
        max-width: 80px;
        display: block;
        margin: 0 auto 15px;
        max-height: 80px;
      }

      .file-name {
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        line-height: 20px;

        .blue-text {
          color: $blue2;
          font-weight: 600;
          display: inline-block;
          margin-right: 4px;
        }
      }

      .small-text {
        font-size: 12px;
        line-height: 18px;
        margin: 2px 0 0;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 10px;
        position: relative;
        z-index: 1;

        li {
          padding: 4px 8px;
          border: 1px solid $light-gray4;
          border-radius: 8px;
          font-size: 14px;
          line-height: 20px;
          display: inline-flex;
          align-items: center;

          span {
            display: inline-flex;
            margin-left: 3px;
            cursor: pointer;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .placeholder-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;

      img {
        margin: 0 0 12px;
      }

      .blue-text {
        color: $blue2;
        font-weight: 600;
        display: inline-block;
        margin-right: 4px;
      }

      .small-text {
        font-size: 12px;
        line-height: 18px;
        margin: 4px 0 0;
      }
    }
  }

  &.checkbox-wrap {
    .MuiFormControlLabel-root {
      .MuiTypography-root {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.42;
        color: $gray3;
        margin: 0;
        display: block;
        font-family: $primary-fonts;
      }

      .MuiSvgIcon-root {
        fill: $light-gray4;
        width: 25px;
        height: 25px;
      }

      .Mui-checked {
        .MuiSvgIcon-root {
          fill: $primary-color;
        }
      }
    }
  }

  &.textarea {
    .form-control {
      .MuiInputBase-root {
        height: auto;
        padding: 0;
      }
    }
  }

  &.select-page-dropdown {
    min-width: 90px;
    position: absolute;
    left: 30px;
    bottom: 40px;
  }
}

.btn {
  outline: none !important;
  font-size: 16px !important;
  line-height: 1 !important;
  font-weight: 600 !important;
  padding: 10px 18px !important;
  height: 44px !important;
  font-family: $primary-fonts !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid transparent !important;
  text-transform: unset !important;
  border-radius: 8px !important;

  i {
    svg {
      height: 20px;
      width: 20px;
      display: block;
      @include transition(all 0.3s);
    }

    &.right {
      margin-left: 6px;
    }

    &.left {
      margin-right: 6px;
    }
  }

  &.btn-sm {
    padding: 8px 14px !important;
    height: 36px !important;
    font-size: 14px !important;
  }

  &.grey-btn {
    background-color: $gray6 !important;
    color: $white !important;

    i {
      svg {
        color: $white !important;
      }
    }

    &:hover {
      color: $white !important;
      background-color: $light-gray5 !important;

      i {
        svg {
          color: $primary-color !important;
        }
      }
    }
  }

  &.white-btn {
    background-color: $white !important;
    border-color: $gray6 !important;
    color: $black !important;

    &:hover {
      color: $primary-color !important;
      background-color: rgba($light-blue2, 0.2) !important;

      i {
        svg {
          color: $primary-color !important;
        }
      }
    }
  }

  &.primary-btn {
    background-color: $primary-color !important;
    color: $white !important;
    border-color: $primary-color !important;
    text-align: center;

    i {
      svg {
        color: $white !important;
        stroke: $white;
      }
    }

    &:hover {
      color: $primary-color !important;
      background-color: $white !important;

      i {
        svg {
          color: $primary-color !important;
          stroke: $primary-color;
        }
      }
    }
  }

  &.transparent-btn {
    color: $gray3 !important;
    background-color: transparent !important;

    i {
      svg {
        color: $gray5 !important;
      }
    }

    &:hover {
      color: $primary-color !important;

      i {
        svg {
          color: $primary-color !important;
        }
      }
    }

    .MuiTouchRipple-root {
      .MuiTouchRipple-child {
        background-color: $light-gray4 !important;
      }
    }
  }

  &.border-btn {
    background-color: transparent !important;
    color: $gray3 !important;
    border-color: $light-gray4 !important;

    i {
      svg {
        color: $gray3 !important;
      }
    }

    &:hover {
      color: $gray3 !important;
      background-color: $light-gray4 !important;

      i {
        svg {
          color: $white !important;
        }
      }
    }
  }

  &.blue-fill-btn {
    background-color: $light-blue1 !important;
    color: $blue2 !important;
    border-color: $light-blue1 !important;

    i {
      svg {
        color: $blue2 !important;
      }
    }

    &:hover {
      color: $white !important;
      border-color: $primary-color !important;
      background-color: $primary-color !important;

      i {
        svg {
          color: $white !important;
        }
      }
    }
  }

  &.danger-fill-btn {
    background-color: $error !important;
    color: $error2 !important;
    border-color: $error !important;

    i {
      svg {
        color: $error2 !important;
      }
    }

    &:hover {
      color: $white !important;
      border-color: $error2 !important;
      background-color: $error2 !important;

      i {
        svg {
          color: $white !important;
        }
      }
    }
  }
}

.icon-btn {
  background-color: $light-gray6 !important;
  height: 40px;
  width: 40px;
  padding: 0 !important;

  svg {
    color: $gray5 !important;
    width: 20px;
    height: 20px;
  }
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;

  @include bp(tablet-max) {
    @include mb-2();
  }
}

.custom-dropdown {
  .MuiPaper-root {
    margin: 8px 0 0;
    @include box-shadow(0px, 12px, 16px, -4px, #10182814);
    border: 1px solid $light-gray3;
    border-radius: 8px;
    min-width: 130px;
  }

  .MuiList-root {
    padding: 0;

    .MuiMenuItem-root {
      padding: 15px 16px;
      font-size: 14px;
      line-height: 1.42;
      color: $gray3;
      font-weight: 500;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $light-gray3;
      }

      i {
        svg {
          height: 20px;
          width: 20px;
          display: block;
        }

        &.right {
          margin-left: 8px;
        }

        &.left {
          margin-right: 8px;
        }
      }

      &:hover {
        color: $primary-color;
        background-color: rgba($light-blue2, 0.2);
      }

      &.in-active {
        color: $light-gray5;
      }

      .MuiTouchRipple-root {
        .MuiTouchRipple-child {
          background-color: $light-blue2 !important;
        }
      }
    }
  }

  &.position-mobile {
    @include bp(tablet-max) {
      .MuiPaper-root {
        left: auto !important;
        right: 25px !important;
        width: 250px;
        margin: 0 !important;
        border-radius: 0;
      }

      .MuiList-root {
        .MuiMenuItem-root {
          padding: 12px 16px !important;
        }
      }
    }
  }
}

.custom-select {
  width: 100%;
  height: 44px;
  border-radius: 8px !important;

  &.icon-select {
    height: auto;
    width: auto;

    .MuiSelect-select {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }

  .MuiSelect-select {
    padding: 10px 14px;
    font-size: 16px;
    line-height: 1.5;
    font-family: $primary-fonts !important;
    color: $gray1;
    letter-spacing: 0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
  }

  .MuiSelect-icon {
    width: 22px;
    height: 22px;
    color: $gray5;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $light-gray4 !important;
    @include box-shadow(0px, 1px, 2px, 0px, #1018280d);

    legend {
      span {
        margin: 0 !important;
      }
    }
  }

  &:hover,
  &.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $light-gray4;
    }
  }

  &.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: $red2 !important;
    }

    svg {
      color: $red1 !important;
    }
  }

  .MuiInputAdornment-root {
    height: 100%;

    svg {
      width: 22px;
      height: 22px;
      color: $gray5;
    }
  }
}

.custom-dropdown-menu {
  &.icon-select-menu {
    .MuiList-root {
      .MuiMenuItem-root {
        padding: 10px 16px;

        img {
          width: 30px;
          height: 30px;
          margin: 0 auto;
        }
      }
    }
  }

  .MuiList-root {
    padding: 0;

    .MuiMenuItem-root {
      padding: 15px 16px;
      font-size: 14px;
      line-height: 1.42;
      color: $gray3;
      font-weight: 500;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid $light-gray3;
      }

      i {
        svg {
          height: 20px;
          width: 20px;
        }

        &.right {
          margin-left: 8px;
        }

        &.left {
          margin-right: 8px;
        }
      }

      &:hover {
        color: $primary-color;
        background-color: rgba($light-blue2, 0.2);
      }

      .MuiTouchRipple-root {
        .MuiTouchRipple-child {
          background-color: $light-blue2 !important;
        }
      }
    }
  }
}

.overlay {
  background-color: rgba($gray1, 0.58);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.MuiAlert-outlined {
  margin: 0 0 24px;
  border-radius: 12px !important;
  font-size: 14px !important;
  line-height: 1.42 !important;
  font-weight: 600 !important;
  align-items: center;
  min-height: 44px;

  .MuiAlert-action {
    padding: 0;
  }

  .MuiAlert-message {
    padding: 0;
  }

  .MuiAlert-icon {
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.MuiAlert-outlinedError {
    border-color: $red2;
    background-color: $red3;
    color: $red1;

    .MuiAlert-icon {
      color: $red1;
    }
  }
}

.card-outline {
  border: 1px solid $light-gray4;
  padding: 30px;
  @include border-radius(16px);
  width: 100%;

  @include bp(md-max) {
    padding: 25px;
  }

  @include bp(xxxs-min) {
    padding: 15px;
  }

  &.no-border {
    border: none;
  }

  &:not(:last-child) {
    margin: 0 0 30px;
  }

  &.form {
    max-width: 1200px;
    margin: 0 auto;

    @include bp(lg-min) {
      max-width: 1000px;
    }

    @include bp(lmd-max) {
      max-width: 1200px;
    }

    @include bp(xxxl-md) {
      max-width: 1500px;
    }

    @include bp(xxxl-max) {
      max-width: 1200px;
    }

    @include bp(xxxl-min) {
      max-width: 1000px;
    }
  }

  .card-title-wrap,
  .card-inner-title-wrap {
    &.secondary-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      h3 {
        margin: 0;
        padding-right: 10px;
      }
    }
  }

  .card-title-wrap {
    margin: 0 0 24px;

    &.full-width {
      margin: 0 -30px 15px;
    }

    .title-full-width {
      margin: 0 22px 5px;
    }

    border-bottom: 1px solid $light-gray4;

    h3 {
      margin: 0 0 24px;

      @include bp(md-max) {
        margin: 0 0 20px;
      }
    }

    &.no-border-title {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  .card-inner-title-wrap {
    margin: 30px 0 0px;
    padding: 30px 0 0;
    border-top: 1px solid #d0d5dd;
  }

  h5 {
    color: $gray2;
  }

  .row-wrap {
    margin: 0 -22px -30px;

    &.margin-40 {
      margin: 40px -22px;
    }

    &.full-width {
      margin: 0 -30px -30px;
    }

    &.professional-margin {
      margin-top: 20px;
    }

    &.attestation-margin {
      margin-top: 20px;
    }

    display: flex;
    flex-wrap: wrap;

    @include bp(lmd-max) {
      margin: 0 -12px -20px;
    }

    &.w-70 {
      max-width: 780px;

      @include bp(md-max) {
        max-width: unset;
      }
    }

    .form-group {
      padding: 0 22px;
      margin: 0 0 30px;
      flex: 0 0 50%;
      max-width: 50%;

      @include bp(lmd-max) {
        padding: 0 12px;
        margin: 0 0 20px;
      }

      @include bp(xs-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &.second-button-attestation {
        padding: 0 12px;
        margin: 0 0 20px;

        @include bp(xs-max) {
          margin: 0 -8px 20px;
        }
      }

      &.w-full {
        flex: 0 0 100%;
        max-width: 100%;

        .row-wrap:not(:last-child) {
          margin-bottom: 0;
        }
      }

      &.w-full-reopen {
        flex: 0 0 100%;
        max-width: 84%;
      }

      &.w-full-reopen-calendar {
        flex: 0 0 100%;
        max-width: 80%;
      }

      &.w-full-reopen-outsource {
        flex: 0 0 100%;
        max-width: 80%;
      }

      &.w-full-only-reopen {
        flex: 0 0 100%;
        max-width: 80%;
      }

      .switch-wrap {
        display: flex;
        gap: 15px;

        .form-label {
          color: $gray5;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &.attestation-width {
        max-width: 44%;
        margin-bottom: 10px;
        margin-top: 1px;

        @include bp(md-max) {
          max-width: unset;
        }

        @include bp(lmd-max) {
          max-width: 42%;
          margin-bottom: 10px;
          margin-top: 1px;
        }

        @include bp(xxss-max) {
          max-width: unset;
        }

        @include bp(xxxs-min) {
          max-width: unset;
        }
      }

      &.attestation-width-button {
        max-width: 5%;
        margin-top: 21px !important;
      }

      &.attestation-width-button.no-margin {
        margin-top: 20px !important;
      }
    }

    &.review-row {
      margin: 7px -22px -30px;
    }
  }

  .sd-row {
    &.sd-row--multiple {
      margin-left: -15px;

      >div {
        padding-left: 15px;
      }
    }
  }

  &.pdf-content-outline {
    border: none !important;
  }

  &.select-dropdown-survay {
    position: relative;
  }

  .client-review-table {
    margin: 0 0 24px;
  }
}

.right-inner {
  &.record-create-edit {
    .delete-button {
      max-width: 1200px;
      margin: 20px auto;

      @include bp(lg-min) {
        max-width: 1000px;
      }

      @include bp(lmd-max) {
        max-width: 1200px;
      }

      @include bp(xxxl-md) {
        max-width: 1500px;
      }

      @include bp(xxxl-max) {
        max-width: 1200px;
      }

      @include bp(xxxl-min) {
        max-width: 1000px;
      }
    }
  }
}

.custom-switch {
  &.MuiSwitch-root {
    height: 20px;
    width: 36px;
    padding: 0;
    @include border-radius(12px);

    .MuiSwitch-switchBase {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      width: 20px;
      height: 20px;

      &.Mui-checked {
        @include translateX(16px);

        +.MuiSwitch-track {
          background-color: $blue3;
          opacity: 1;
        }
      }

      .MuiSwitch-thumb {
        width: 15.5px;
        height: 15.5px;
        box-shadow: 0px 1px 3px 0px #1018281a !important;
        background-color: $white;
      }
    }

    .MuiSwitch-track {
      height: 20px;
      width: 36px;
      background-color: $light-gray2;
      @include border-radius(12px);
      opacity: 1;
    }
  }
}

.custom-switch-inside {
  &.MuiSwitch-root {
    height: 30px;
    width: 90px;

    .MuiSwitch-track {
      height: 30px;
      width: 90px;

      &::before {
        content: 'No';
        display: inline-block;
        position: absolute;
        left: 16px;
        top: 4px;
        z-index: 1;
        font-size: 13px;
      }

      &::after {
        content: 'Yes';
        display: inline-block;
        position: absolute;
        right: 14px;
        top: 4px;
        font-size: 13px;
      }
    }

    .MuiSwitch-switchBase {
      width: 45px;
      height: 29px;
      transform: translateX(3px);

      &.Mui-checked {
        @include translateX(42px);

        +.MuiSwitch-track {
          background-color: #eeeeee;

          &::after {
            z-index: 1;
            color: $white;
          }

          &::before {
            z-index: 0;
            color: $black;
          }
        }
      }

      .MuiSwitch-thumb {
        width: 40px;
        height: 23px;
        background-color: $green2;
        box-shadow: none !important;
      }
    }
  }
}

.custom-switch-open {
  .MuiSwitch-thumb {
    background-color: $white !important;
  }
}

.custom-check {
  svg {
    display: none;
  }

  &:after {
    content: '';
    border: 1px solid $light-gray4;
    width: 20px;
    height: 20px;
    padding: 0;
    background: $white;
    border-radius: 6px;
  }

  &:before {
    content: '';
    display: inline-block;
    height: 12px;
    width: 6px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
  }

  &.MuiCheckbox-indeterminate {
    &:before {
      top: 49%;
      transform: translateY(-50%);
      height: 0;
      width: 12px;
    }
  }

  &.Mui-checked,
  &.MuiCheckbox-indeterminate {
    &:after {
      background: $primary-color;
      border-color: $primary-color;
    }
  }

  &.custom-check-other {

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      &:after {
        background: $light-blue1;
        border-color: $blue2;
      }

      &:before {
        border-bottom: 2px solid $blue2;
        border-right: 2px solid $blue2;
      }
    }
  }
}

.check-outer {
  .MuiFormControlLabel-root {
    margin-left: -9px;
  }
}

.table-outer {
  margin: 0 0 40px;
  border: 1px solid $light-gray3;
  border-radius: 12px;

  @include bp(sm-max) {
    margin: 0 0 25px;
  }

  .title-wrapper {
    padding: 20px 23px;
    border-bottom: 1px solid $light-gray3;

    @include bp(xxs-max) {
      padding: 15px;
    }
  }

  &.table-space {
    margin-top: 50px;
  }
}

.table {
  tr {
    &.collapse {
      background: $light-blue;

      td {
        border: 0;
      }
    }

    &.collapse-open {
      td {
        border: 0;
        padding: 0;
      }

      .MuiCollapse-wrapper {
        background: $light-blue;
        padding: 0 24px 22px;
      }
    }

    .collapse-description {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $gray4;
      margin: 15px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;
    }

    .file-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      li {
        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: $gray3;
          border: 1px solid #d0d5dd;
          border-radius: 8px;
          background: #fff;
          padding: 8px 14px;
        }
      }
    }

    th,
    td {
      border-bottom: 1px solid $light-gray3;
      letter-spacing: 0;
    }

    th {
      background: $light-gray1;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: $gray4;
      padding: 13px 10px;
      white-space: nowrap;

      @include bp(xxs-max) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .MuiTableSortLabel-root {
        &:after {
          content: '';
          background: url(../assets/images/chevron-selector.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 8px;
          height: 12px;
          margin-left: 8px;
        }

        svg {
          display: none;
        }
      }
    }

    td {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $gray4;

      @include bp(xxs-max) {
        padding-left: 15px;
        padding-right: 15px;
      }

      &.overdue-status {
        color: $red1;
        font-weight: 500;
      }

      &.right-arrow-width {
        width: 70px;
      }

      &.unique-id-width {
        width: 125px;
      }

      .overdue-status {
        color: $red1;
        font-size: 16px;
        line-height: 1.5;
        margin: 5px 0;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $gray4;

        &.limitation-other-data {
          font-size: 16px;
          line-height: 1.5;
          margin: 5px 0;
        }
      }

      .name {
        color: $gray1;
        font-weight: 500;
      }

      .content {
        padding-left: 18px;
      }

      &.open {
        span {
          color: $green1;
          background: $green3;
          // width: 50px;
          text-align: center;
          padding: 4px 12px;
        }
      }

      &.completed {
        span {
          color: $green1;
          background: $green3;
          // width: 80px;
          text-align: center;
          padding: 4px 12px;
        }
      }

      &.closed {
        span {
          color: $gray3;
          background: $light-gray2;
          // width: 70px;
          text-align: center;
          padding: 4px 12px;
        }
      }

      &.overdue {
        span {
          color: $error1;
          background: $error;
          // width: 50px;
          text-align: center;
          padding: 4px 12px;
        }
      }

      &.opened {
        span {
          color: $warning1;
          background: $warning;
          // width: 50px;
          text-align: center;
          padding: 4px 12px;
        }
      }

      span {
        // padding: 4px 12px;
        border-radius: 16px;
        display: inline-block;
        font-weight: 500;
      }

      a.file {
        color: $blue2;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .collapse-btn-wrapper {
    display: flex;
    justify-content: flex-end;

    .table-inner-btn {
      padding: 5px;
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
    }

    button {
      min-width: 40px;
      min-height: 40px;

      &:hover {
        background: rgba(25, 118, 210, 0.04);
      }

      span {
        padding: 0;
        border-radius: inherit;
      }
    }
  }

  &.table-checkbox {
    tr {

      th,
      td {
        padding-left: 13px;
        padding-right: 13px;

        @include bp(xxs-max) {
          padding-left: 5px;
        }
      }
    }
  }

  &.hover-table {
    tbody {
      tr {
        &:hover {
          background-color: $light-blue;
        }
      }
    }
  }

  tbody {
    tr {

      &.Mui-selected,
      &.Mui-selected:hover {
        background-color: transparent;
      }
    }
  }

  .custom-check {
    margin: -9px 0;
  }
}

.table-btn {
  display: flex;
  align-items: center;

  button {
    min-width: 1px !important;
    padding: 7px;
    width: 38px;
    height: 38px;
    border-radius: 50%;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.error-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-container-inner {
    padding: 50px 25px;
    text-align: center;
  }

  .img-wrap {
    max-width: 300px;
    margin: 0 auto 20px;
  }

  .content-wrap {
    h1 {
      margin: 0 0 10px;
    }
  }
}

.MuiAlert-root {
  display: inline-flex !important;
}

.row-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

:root {
  --sjs-primary-backcolor: #1da2ef;
  /* Change the color to red */
}

.pdf-flex {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.sd-btn--action:hover {
  background-color: #1da2ef !important;
}

.sd-question__erbox {
  display: none !important;
}

.sd-input--error {
  border: 1px solid #fda29b !important;
  background-color: none;
}

.corner-exit-mark {
  width: calc(100% - 285px);
  display: flex;
  justify-content: space-between;
}

.sd-action-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sd-navigation__prev-btn {
  background-color: transparent !important;
  color: #344054 !important;
  border-color: #d0d5dd !important;
  padding: 8px 14px !important;
  height: 36px !important;
  font-size: 14px !important;
}

.sd-navigation__next-btn {
  background-color: #1da2ef !important;
  color: #fff !important;
  border-color: #1da2ef !important;
  padding: 8px 14px !important;
  height: 36px !important;
  font-size: 14px !important;
}

.sd-navigation__complete-btn {
  background-color: #1da2ef !important;
  color: #fff !important;
  border-color: #1da2ef !important;
  padding: 8px 14px !important;
  height: 36px !important;
  font-size: 14px !important;
}

#survey_clear_current_page .sv-action__content input {
  background-color: transparent !important;
  color: #344054 !important;
  border-color: #d0d5dd !important;
  padding: 8px 14px !important;
  height: 36px !important;
  font-size: 14px !important;
}

.inner-wrap-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-left: 20px;

  h5 {
    color: #667085;
  }

  &.inner-wrap-title-center {
    display: block;
    padding: 16px;
    text-align: center;

    p {
      font-size: 14px;
    }
  }

  &.inner-color-wrap {
    color: #344054;
  }

  .margin-top {
    margin-top: 15px;
  }

  &.inner-no-margin {
    margin-left: 0;
    font-weight: 401;
    font-size: 14px;
  }
}

.no-register-available {
  color: #98a2b3;
  font-size: 14px;
  font-weight: 400;
}

.svg-css {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.row-width {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px !important;
  min-height: 5px !important;
}

.error {
  color: $red1;
  font-size: 20px;
}

@media print {
  body {
    font-size: 12pt;
  }
}

.svc-string-editor .sv-string-editor {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  color: #344054;
  margin: 0 0 6px;
  display: block;
}

.title-padding {
  padding-bottom: 10px;
}

.template-description {
  margin: 0px 0 10px 5px;
}

.value-title {
  margin-bottom: 20px;
}

.fc-toolbar-title {
  font-size: medium !important;
  padding: 20px 23px;
}

.calendar-box-color {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 4px;
}

.fc {
  .fc-listYear-button {
    margin-right: 10px !important;
  }

  .fc-prev-button {
    margin-left: 5px !important;
  }

  .fc-next-button {
    margin-right: 5px !important;
  }

  .fc-dayGridMonth-button {
    margin-right: 10px !important;
  }

  .fc-button-group {

    .fc-dayGridMonth-button,
    .fc-listYear-button {
      border-bottom-right-radius: 5px !important;
      border-top-right-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
      border-top-left-radius: 5px !important;
    }
  }

  .fc-button-primary {
    background-color: $primary-color !important;
    border-color: transparent !important;
  }

  .fc-toolbar-title {
    font-size: 28px !important;
  }
}

.calendar-wrap-btn {
  margin-top: 5px;
}

.custom-color-dropdown {
  width: 100%;
  height: 20px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.MuiButtonBase-root {
  .custom-color-dropdown {
    display: none;
  }
}

.custom-event-content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* Adjust column width as needed */
  gap: 8px;
  /* Adjust the gap between columns */
  padding: 4px 10px 4px 10px;
  border-radius: 5px;

  .event-subject,
  .event-action {
    margin-left: 2px;
    font-weight: bold;
    color: $white;
    white-space: normal;
    cursor: pointer;
  }
}

.custom-event-content-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Center vertically */
  gap: 8px;

  /* Adjust the gap between items */
  .event-title {
    font-weight: bold;
  }

  .event-subject {
    width: 35%;
    color: #888;
    font-weight: bold;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    /* Add ellipsis for overflow text */
  }

  .event-action {
    width: 40%;
    color: #888;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }

  .event-recurrence {
    width: 20%;
    color: #888;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
  }
}

.fc-list-event-time {
  display: none;
}

.container-boxs {
  padding: 0 10px 16px;

  .each-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >* {
      margin: 7px 5px;
    }

    .box {
      width: 60px;
      height: 44px;
      background-color: $white;
      border: 1px solid #d0d5dd;
      border-radius: 8px;
      padding: 10px;
      text-align: center;

      &.box-red-border {
        border: 1px solid $red2;
      }

      &:last-child {
        margin-right: 0;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    .custom-select {
      &.dropdown-1 {
        width: 150px;
      }

      &.dropdown-2 {
        width: 105px;

        &.dropdown-month {
          width: 130px;
        }
      }
    }
  }
}

.agenda-wrap {
  .agenda-question-wrap {
    &:not(:last-child) {
      margin: 0 0 30px;
    }

    .question {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 10px;

      p {
        color: $gray3;
        font-size: 14px;
        font-weight: 500;
        margin-right: 10px;
        flex: 1;
      }

      .info {
        width: 20px;
        height: 20px;
        min-width: 20px;
      }

      .right {
        display: inline-flex;
        align-items: center;
        margin: 0 -5px;

        @include bp(sm-max) {
          flex: 0 0 100%;
          max-width: 100%;
          justify-content: flex-end;
        }

        .na {
          display: inline-block;
          font-size: 14px;
          line-height: 1.5;
          font-weight: 500;
          color: $gray5;
        }

        >* {
          margin: 0 5px;
        }
      }
    }

    .form-label {
      color: $gray5;
      letter-spacing: -0.01px;
    }

    .name-date-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px -10px;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 40px);

      @include bp(xs-max) {
        width: 100%;
      }

      span {
        font-size: 14px;
        line-height: 1.42;
        display: block;
        color: $gray5;
        padding: 0 10px 10px;
        margin: 0 0 10px;
      }
    }
  }

  &.with-top-border {
    margin: 30px 0 0;
    padding: 30px 0 0;
    border-top: 1px solid #d0d5dd;
  }

  h5 {
    margin: 0 0 20px;
  }
}

.open-tasks {
  i {
    cursor: pointer;
  }
}

.table-outer {
  &.table-sm-outer {
    margin: 0 0 10px;
    overflow: hidden;

    .table {
      margin: 0;
    }
  }
}

.MuiDialog-root {
  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .MuiPaper-root {
    box-shadow: none;
    border-radius: 16px;
    border: 1px solid $gray5;
    max-width: 770px;
    width: 100%;
  }

  &.custom-dialog-sm {
    .MuiPaper-root {
      max-width: 450px;
    }

    p {
      margin: 25px 0px 0;
      font-size: 16px !important;
    }
  }
}

.model-wrapper {
  padding: 20px 30px;

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin: 0 0 20px;
    border-bottom: 1px solid $light-gray4;
  }

  .modal-description {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-gray4;

    p {
      font-size: 14px;
      font-weight: 500;
      color: $gray3;
    }
  }
}

.log-modal {
  .model-wrapper {
    padding-bottom: 0;

    @include bp(md-max) {
      padding: 20px 25px 0 25px;
    }

    @include bp(xxxs-min) {
      padding: 15px 15px 0 15px;
    }

    .modal-title {
      h2 {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 600;
        color: #1d2939;

        @include bp(sm-max) {
          font-size: 18px;
        }
      }
    }
  }

  .card-outline {
    border: 0;
    padding-top: 0;

    .sv-components-row {
      .sd-body {
        padding: 0;
      }
    }

    .sd-header__text {
      .sd-title {
        margin: 0 0 24px !important;
        padding: 0 0 10px;

        @include bp(sm-max) {
          font-size: 18px;
        }
      }
    }
  }
}

.notification-update-filter {
  width: 1200px;
}

@media (max-width: 1200px) {
  .notification-update-filter {
    width: 100%;
    /* Make the element use full width */
  }
}

.notification {
  min-height: 240px;
  padding: 0px;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;

  @include border-radius(7px);

  .type-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    position: relative;
    font-size: 14px;
    color: $white;
    padding-top: 7px;
    padding-bottom: 15px;

    .type {
      font-weight: 600;
      margin-left: 15px;
    }

    .date {
      font-weight: 400;
      display: flex;
      line-height: 20px;
      margin-right: 15px;

      span {
        margin-left: 10px;
      }
    }
  }

  .title {
    padding-top: 10px;

    h2 {
      font-weight: 600;
    }
  }

  .summary {
    font-weight: 600;
  }

  .summary,
  .details {
    font-size: 14px;
    margin-top: 15px;

    p {
      font-size: 14px;
    }
  }

  .link {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    color: $primary-color;

    a {
      i {
        margin-left: 10px;
      }
    }
  }

  .img-updates {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .update-tiny-html {
    margin-top: 5px;
    margin-bottom: 5px;
    list-style-position: inside;
  }
}

@media (max-width: 1200px) {
  .notification {
    width: 100%;
    /* Make the element use full width */
  }
}

.notification-image {
  max-width: 170px;
  height: 114px;
  width: 85%;
  border-radius: 8px;
}

.notification-grid {
  padding-left: 20px;
}

.notification .title {
  padding-left: 20px;
}

.update-image {
  max-width: 300px;
  height: 200px;
  width: 90%;
  margin-top: 15px;
  margin: auto;
  @include border-radius(15px);
}

.update .details {
  margin-left: 20px;
}

.update .summary {
  margin-left: 20px;
}

.update {
  .notification {
    min-height: 325px;
  }

  .link {
    margin-top: 50px;
    margin-left: 20px;
    margin-bottom: 20px;

    a {
      display: flex;
      flex-wrap: wrap;

      i {
        margin-top: 2px;
        margin-left: 10px;
      }
    }
  }

  .previous-btn {
    margin-top: 20px;
  }
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.error-inputs {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: $red1;
}

.manage-meeting {
  .form-group {
    p.form-control {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

.dashboard-form-outer {
  margin: 0 0 40px;

  @include bp(md-max) {
    margin: 0 0 30px;
  }

  @include bp(sm-max) {
    margin: 0 0 20px;
  }

  form {
    max-width: 360px;
  }
}

.dashboard-outer-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  padding: 40px 0;

  @include bp(xxxl-max) {
    margin: 0 -15px;
  }

  .dashboard-left-col {
    @include bp(xlg-max) {
      border-right: 1px solid $light-gray3;
    }

    @include bp(xs-max) {
      border: 0;
    }
  }

  .dashboard-left-col,
  .dashboard-right-col {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 0 30px;

    @include bp(xxxl-max) {
      padding: 0 15px;
    }

    @include bp(xxxl-md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .dashboard-left-col {
    @include bp(xxxl-md) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    @include bp(lg-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .dashboard-middles-col {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 30px;
    border-left: 1px solid $light-gray3;
    border-right: 1px solid $light-gray3;

    @include bp(xxxl-max) {
      padding: 0 15px;
    }

    @include bp(xxxl-md) {
      flex: 0 0 75%;
      max-width: 75%;
    }

    @include bp(lg-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.upload-premium-blog {
  margin: 0 0 29px;

  // padding: 30px;
  @include bp(xlg-max) {
    padding: 12px;
  }

  @include bp(xs-max) {
    padding: 0px;
  }

  a {
    display: block;

    img {
      max-width: 100%;
      margin: 0 auto;
      // border-top-left-radius: 20px;
      // border-top-right-radius: 20px;
      border-radius: 20px;
    }
  }

  .btn-wrap {
    justify-content: flex-start;
    margin: 0;
    background-color: $purple;
    padding: 0 15px 15px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 400px;
    margin: 0 auto;

    .btn {
      color: $primary-color;
      background-color: $white;
      margin: 0;
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
      white-space: nowrap;
      padding: 8px 5px !important;
      height: auto !important;
      line-height: normal;
      font-size: 14px !important;
      text-align: center;

      @include bp(xxss-max) {
        font-size: 12px !important;
      }

      cursor: pointer;

      &:last-child {
        color: $white;
        border-color: $white !important;
        background-color: transparent;
        margin-left: 10px;
      }
    }
  }
}

.task-outer {
  h5 {
    margin: 0 0 20px;

    @include bp(xs-max) {
      margin: 0 0 15px;
    }
  }
}

.task-inner {
  .task-list {
    margin: 0 0 20px;
  }

  .task-wrapper {
    background: #f6f6f6;
    border-radius: 10px;
    padding: 10px;
    width: 100%;

    h6 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $gray3;
      margin: 0 0 10px;
    }

    .task-des-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      p {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: $gray5;
      }
    }
  }
}

.update-outer {
  h5 {
    margin: 0 0 27px;

    @include bp(xs-max) {
      margin: 0 0 15px;
    }
  }
}

.update-inner {
  .update-list {
    margin: 0 0 30px;
    @include border-radius(10px);

    @include bp(xs-max) {
      margin: 0 0 20px;
    }
  }

  .update-title {
    background: $blue3;
    color: $white;
    border-radius: 8px 8px 0 0;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .update-content {
    padding: 10px 8px 0;

    h5 {
      margin: 0 0 10px;
    }

    .update-img-wrapper {
      margin: 0 18px 10px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 10px;
      padding-right: 17px;

      @include bp(xxl-max) {
        padding-right: 0px;
      }
    }

    .text-btn {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $blue3;
      display: inline-flex;
      align-items: center;
      margin-left: 7px;

      .icon {
        height: 20px;
        margin-left: 8px;
      }

      &:hover {
        color: $gray3;
      }
    }
  }
}

.dashboard-list {
  margin: 0 0 10px;

  h5 {
    margin: 0 0 20px;

    @include bp(sm-max) {
      margin: 0 0 15px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    @include bp(xxxl-max) {
      margin: 0 -15px;
    }

    li {
      flex: 0 0 20%;
      max-width: 20%;
      padding: 0 20px;
      margin-bottom: 30px;

      @include bp(xxxl-min) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @include bp(xxxl-max) {
        padding: 0 15px;
      }

      @include bp(xl-max) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      @include bp(sm-max) {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 20px;
      }

      @include bp(xxs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }

      a {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background: $blue3;
        border-radius: 12px;
        transition: 0.3s all;
        cursor: pointer;
        height: 100%;
        min-height: 92px;

        @include bp(xxxl-max) {
          padding: 10px 15px;
        }

        @include bp(xxl-max) {
          padding: 10px 10px;
        }

        @include bp(sm-max) {
          padding: 8px 10px;
        }

        .dashboard-icon {
          width: 50px;
          height: 50px;
          object-fit: contain;

          @include bp(xxxl-max) {
            width: 40px;
            height: 40px;
          }

          @include bp(lg-max) {
            width: 30px;
            height: 30px;
          }
        }

        i {
          color: $light-blue2;
          max-width: 50px;
          min-width: 50px;
          margin-right: 5px;
          display: inline-flex;

          @include bp(xxxl-max) {
            max-width: 40px;
            min-width: 40px;
          }

          @include bp(lg-max) {
            max-width: 30px;
            min-width: 30px;
          }

          @include bp(xxs-max) {
            margin-right: 10px;
          }
        }

        .content-wrapper {
          text-align: center;
          flex: 1;
          display: flex !important;
          flex-direction: column;

          @include bp(xxs-max) {
            text-align: left;
          }

          h6 {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: $white;
            margin: 0 0 14px;
          }

          p {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: $blue4;
          }
        }

        &.in-active,
        &:hover {
          background: $light-gray3 !important;

          i {
            color: $light-gray5;
          }

          .content-wrapper {
            h6 {
              color: $gray5;
            }

            p {
              color: $light-gray5;
            }
          }
        }
      }
    }
  }
}

.dashboard-icon {
  width: 35px;
  height: 35px;
}

.pagination {
  .custom-select {
    width: 100px;
  }
}

.limitation-heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  color: $gray3;
  margin: 0 0 6px;
  display: block;
}

.signup-content,
.signup-success-content {
  margin: 20px 0 50px;

  h3 {
    font-size: 35px;
    color: $primary-color;
    margin: 0 0 10px;
  }

  .info-text {
    color: $gray5;
  }

  .signup-form {
    display: flex;
    justify-content: space-around;
    margin: 40px 0 0;

    .card-outline {
      width: 500px;
      padding: 30px 80px;

      .inner-btn-wrap {
        margin: 30px 0 0;
        justify-content: space-around;

        .mb-40 {
          margin-bottom: 40px;
        }
      }
    }
  }
}

.signup-success-content {
  h3 {
    margin: 0 0 50px;
  }

  // .info-text {
  //   line-height: 3rem;
  // }
  .info-text p {
    margin-bottom: 1.5rem;
  }

  .inner-btn-wrap {
    margin: 30px 0 0;
    justify-content: space-around;

    .btn {
      width: 200px;
    }
  }
}

.policy-list {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    li {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0 15px;
      margin: 0 0 30px;

      @include bp(md-max) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }

      @include bp(sm-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include bp(xxs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px;
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 16px 35px;
        border-radius: 12px;
        background: $blue3;
        text-align: center;
        height: 100%;

        @include bp(lg-max) {
          padding: 16px 25px;
        }

        .icon {
          display: block;
          color: $light-blue2;
          margin: 0 0 24px;

          @include bp(sm-max) {
            margin: 0 0 15px;
          }
        }

        h5 {
          color: $white;
          margin: 0 0 24px;

          @include bp(sm-max) {
            margin: 0 0 15px;
          }
        }

        p {
          font-size: 12px;
          line-height: 18px;
          color: $blue4;
        }
      }
    }
  }
}

.google-chart {
  display: flex;
  margin-bottom: 60px;
}

.margin-chart {
  margin-right: 20px;
}

.report-chart {
  padding: 5px;
  @include border-radius(16px);
  border: 1px solid #d0d5dd;
  width: 100%;

  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .google-visualization-table {
    display: block;

    table {
      width: 100%;
    }
  }
}

.no-tasks {
  font-style: italic;
  color: #667085;
  font-size: 15px;
}

.edit-task-arrow-home {
  cursor: pointer;
}

.collapse-summary {
  height: 60px;
  line-height: 20px;
  /* Height / no. of lines to display */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.pre-content {
  text-wrap: balance;
}

.application-submit-image {
  margin-left: auto;
}

.onboarding-success-img {
  border-radius: 10px;
  max-block-size: 36;
  max-width: 550px;
  width: 100%;
}

.home-read-more {
  margin-left: 0px !important;
}

.dashboard-update-title {
  margin: 10px 0px !important;
}

.dashboard-update-content {
  padding: 10px 20px !important;
}

.dashboard-update-summary {
  margin: 0 0 20px !important;
}

.dashboard-view-all {
  margin-top: 30px !important;
}

.support-request-button {
  width: 200px;
  margin: 60px auto 0;
}

.reset-password {
  background-color: $primary-color !important;
  color: $white !important;
  border-color: $primary-color !important;
  text-align: center;

  i {
    svg {
      color: $white !important;
      stroke: $white;
    }
  }

  &:hover {
    background-color: $primary-color !important;
    color: $white !important;

    i {
      svg {
        color: $primary-color !important;
        stroke: $primary-color;
      }
    }
  }
}

.form-error {
  color: $red1;
  margin: 20px 0 0 !important;
  font-size: 14px !important;
  line-height: 1.42 !important;
}

.dropdown-placeholder {
  color: #98a2b3;
}

.circular-progress-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55 px;
}

.btn-attestation-margin {
  margin-top: 35px !important;
}

.professional-year-new-entrant {
  padding-bottom: 10px;
  margin: 0 0 24px;
  border-bottom: 1px solid $light-gray4;
  display: flex;
  justify-content: space-between;
}

.outer-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;

  @include bp(xs-max) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }

  .btn {
    min-width: 200px;
  }
}

.progress-outer {
  .progress-list {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .progress-inner {
      border: 1px solid $gray6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 16px;
      padding: 14px 30px;
      border-left-width: 30px;

      @include bp(xs-max) {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 12px 15px;
        gap: 15px;
        border-left-width: 20px;
      }

      .content-wrapper {
        h3 {
          margin-bottom: 6px;
        }

        p {
          color: $light-gray5;
        }
      }

      .progress-btn-wrap {
        display: flex;
        gap: 30px;

        @include bp(xs-max) {
          display: grid;
          gap: 20px;
        }

        .btn {
          min-width: 140px;
          margin: 0;
        }
      }

      &.red {
        border-left-color: $red1;

        .content-wrapper {
          p {
            color: $gray1;
          }
        }
      }

      &.green {
        border-left-color: $green2;

        .content-wrapper {
          p {
            color: $gray1;
          }
        }
      }

      &.orange {
        border-left-color: $orange2;

        .content-wrapper {
          p {
            color: $gray1;
          }
        }
      }
    }
  }
}

.sd-body.sd-body--static {
  .sv-components-column {
    .sd-body.sd-body--static .sd-body__navigation.sd-action-bar {
      padding-bottom: 0;
    }
  }
}

.total-hours {
  margin: 20px;

  .form-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42;
    color: $gray3;
    margin: 0 0 6px;
    display: inline-block;

    &.label-size-title {
      font-weight: 500 !important;
    }
  }

  p.form-control {
    color: $gray5;
    display: inline-block;
    margin-left: 10px;
  }
}

// for upgrade page
.main-wrap {
  section {
    padding: 50px 0;

    @include bp(sm-max) {
      padding: 40px 0;
    }

    .container {
      max-width: 1280px;
    }

    .title-wrap {
      margin-bottom: 25px;

      @include bp(sm-max) {
        margin-bottom: 15px;
      }

      .title {
        font-family: $josefin-fonts !important;
        text-transform: uppercase;
        font-size: 42px;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 700;
        line-height: normal;

        @include bp(md-max) {
          font-size: 35px;
        }

        @include bp(sm-max) {
          font-size: 28px;
        }
      }
    }
  }

  .custom-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;

    .custom-col {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 0 30px;

      @include bp(lg-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .content-wrap {
    text-align: center;

    h3 {
      text-transform: uppercase;
      font-family: $josefin-fonts;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 8px;

      @include bp(xs-max) {
        font-size: 14px;
      }
    }
  }

  .btn,
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
    color: $white;
    border-radius: 0px !important;
    background-color: $green4;
    font-family: $josefin-fonts !important;
    font-weight: 600 !important;
    letter-spacing: 0.1em;
    text-transform: uppercase !important;
    font-size: 13px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 40px !important;
    transition: all 0.5s;
    margin: 0 !important;
    height: auto !important;
    line-height: 1.5 !important;

    &.secondary-btn {
      border-color: $green4;

      &:hover {
        filter: brightness(120%);
      }
    }

    &.primary-btn {
      &:hover {
        background-color: $primary-color !important;
        color: $white !important;
        filter: brightness(120%);
      }
    }
  }

  p {
    font-family: $secondary-fonts !important;
    font-weight: 400;
    font-size: 20px;
    text-align: center;

    @include bp(md-max) {
      font-size: 16px;
    }
  }

  .banner-section {
    background: url('https://3lines-public.s3.ap-southeast-2.amazonaws.com/public/banner-bg.webp') center/cover no-repeat;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;

    @include bp(md-max) {
      height: auto;
    }

    @include bp(sm-max) {
      background: url('https://3lines-public.s3.ap-southeast-2.amazonaws.com/public/mobile_banner_bg.webp') center/cover no-repeat;
      background-size: unset;
    }

    .title-wrap {
      padding: 150px 0;
      margin: 0;
      max-width: 900px;
      animation: flip_right 2s ease-in-out;

      @keyframes flip_right {
        0% {
          transform: rotateY(-180deg);
          opacity: 0;
        }

        50% {
          opacity: 0;
        }

        100% {
          transform: rotateY(0deg);
          opacity: 1;
        }
      }

      @include bp(md-max) {
        padding: 75px 0;
      }

      @include bp(sm-max) {
        padding: 34px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        animation: none;
      }

      .title {
        font-style: italic;
        margin-bottom: 1rem;
        color: $white;
        text-align: start;

        @include bp(sm-max) {
          text-align: center;
        }
      }

      .feature-img-wrap {
        width: 400px;
        margin-bottom: 5px;
        max-width: 100%;

        @include bp(md-max) {
          width: 300px;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }

      .btn {
        @include bp(sm-max) {
          width: 100%;
        }
      }
    }
  }

  .needs-section {
    .title {
      color: $green4;
      font-weight: 1000;
    }

    .custom-col {
      @include bp(sm-max) {
        margin-bottom: 8px;
      }
    }

    .img-wrap {
      height: 100px;
      width: 100px;
      margin: 0 auto;
      margin-bottom: 16px;

      img {
        height: 100%;
      }
    }

    .content-wrap {
      color: $gray7;

      h3 {
        color: $gray7;
      }
    }
  }

  .features-section {
    background-color: $dark-blue;

    .title {
      color: $white;

      span {
        color: $primary-color;
      }
    }

    .custom-col {
      padding: 15px;

      .img-wrap {
        height: 50px;
        width: 50px;
        margin: 0 auto;
        margin-bottom: 16px;

        img {
          height: 100%;
        }
      }

      .content-wrap {
        h3 {
          font-weight: 400;
          color: $primary-color;
        }

        p {
          color: $white;
        }
      }
    }
  }

  .trial-section {
    background-color: $green4;
    color: $white;

    .title-wrap {
      margin: 0;

      .title {
        color: $white;
      }

      p {
        font-size: 24px;
        font-family: $pt-sans !important;
        margin-bottom: 8px;

        @include bp(md-max) {
          font-size: 20px;
        }
      }

      .btn-wrap {
        justify-content: center;
        margin: 40px 0 0 0;

        @include bp(md-max) {
          margin-top: 20px;
        }

        @include bp(sm-max) {
          margin-top: 15px;
        }
      }
    }
  }

  .pricing-section {
    text-align: center;

    .title-wrap {
      .title {
        color: $gray7;
        font-weight: 400;

        span {
          font-weight: 700;
          color: $primary-color;

          @include bp(sm-max) {
            font-weight: 600;
          }
        }
      }
    }

    .main-content {
      h4 {
        color: $primary-color;
        font-size: 24px;
        margin-bottom: 8px;
        font-family: $pt-sans !important;
        font-weight: bold;

        @include bp(md-max) {
          font-size: 22px;
        }

        @include bp(sm-max) {
          font-size: 20px;
        }

        @include bp(xs-max) {
          font-size: 18px;
        }
      }

      p {
        color: $gray7;
        margin-bottom: 16px;

        @include bp(md-max) {
          margin-bottom: 12px;
        }

        @include bp(sm-max) {
          margin-bottom: 8px;
        }
      }

      .btn-wrap {
        margin: 0 0 45px 0;

        @include bp(md-max) {
          margin: 0 0 35px 0;
        }

        @include bp(md-max) {
          margin: 0 0 25px 0;
        }
      }

      .img-wrap {
        img {
          margin: 0 auto;
        }
      }
    }

    .btn-wrap {
      justify-content: center;
      margin: 25px 0 0 0;

      @include bp(sm-max) {
        margin: 20px 0 0 0;
      }
    }
  }

  .slider-section {
    background: url('https://3lines-public.s3.ap-southeast-2.amazonaws.com/public/slider_bg.png') center/cover no-repeat;

    .title {
      color: $white;

      span {
        color: $primary-color;
      }
    }

    p {
      color: $white;
    }

    .img-wrap {
      img {
        width: 100%;
      }
    }
  }

  .custom-swiper {
    &.swiper {
      overflow: visible;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: all 0.3s;

      &.swiper-initialized {
        opacity: 1;
        overflow: hidden;
      }

      .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;

        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $green4;
          opacity: 0.15;
          margin: 0 5px;
          cursor: pointer;

          @include bp(sm-max) {
            height: 7px;
            width: 7px;
          }

          @include bp(xxs-max) {
            height: 5px;
            width: 5px;
          }

          &.swiper-pagination-bullet-active {
            opacity: 1;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.selected-plan-wrapper {
  height: auto !important;

  .pre-login-inner {
    align-items: flex-start !important;

    .left-col {
      max-height: none !important;

      .left-inner {
        max-width: 100% !important;
        padding: 50px 66px 50px 80px !important;

        @include bp(xxxl-md) {
          padding: 40px 50px 40px 50px !important;
        }

        @include bp(lg-max) {
          padding: 40px 25px !important;
        }
      }
    }
  }

  h2 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 600;
    color: $primary-color;
    margin: 0 0 50px;
    letter-spacing: -1px;

    @include bp(xlg-max) {
      font-size: 40px;
      line-height: 54px;
      margin: 0 0 40px;
      letter-spacing: 0px;
    }

    @include bp(lg-max) {
      font-size: 34px;
      line-height: 44px;
      margin: 0 0 30px;
    }

    @include bp(sm-max) {
      font-size: 30px;
      line-height: 40px;
      margin: 0 0 20px;
    }
  }

  .content-wrapper {
    margin: 0 0 50px;

    @include bp(xlg-max) {
      margin: 0 0 40px;
    }

    @include bp(lg-max) {
      margin: 0 0 30px;
    }

    @include bp(sm-max) {
      margin: 0 0 20px;
    }

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: #344054;

      &:not(:last-child) {
        margin: 0 0 18px;
      }

      @include bp(md-max) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .plan-list {
    margin-bottom: 50px;

    @include bp(xlg-max) {
      margin-bottom: 40px;
    }

    @include bp(lg-max) {
      margin-bottom: 30px;
    }

    @include bp(sm-max) {
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;

      @include bp(xl-max) {
        margin: 0 -10px;
      }

      li {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 15px;
        margin-bottom: 20px;

        @include bp(xl-max) {
          flex: 0 0 50%;
          max-width: 50%;
          padding: 0 10px;
        }

        @include bp(xxsm-max) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        p {
          font-size: 20px;
          line-height: 30px;
          font-weight: 500;
          border: 1px solid #d0d5dd;
          border-radius: 20px;
          width: 100%;
          height: 100%;
          text-align: center;
          padding: 2px 2px;
          cursor: pointer;

          @include bp(xxxl-md) {
            font-size: 18px;
            line-height: 26px;
          }

          &.selected {
            border: 4px solid #1da2ef;
            background: #f4fbfe;
          }

          .plan-name {
            display: block;
            margin: 10px 5px;
            padding: 0 0 10px;
            border-bottom: 1px solid #d0d5dd;
          }

          .max-users {
            font-size: 16px;
          }

          .img-wrapper {
            display: block;
            text-align: center;
            margin-top: 3px;

            img {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }

  .pre-login-inner {
    .right-image-col {
      .right-inner {
        .img-block {
          img {
            object-position: top center !important;
          }
        }
      }
    }
  }

  .btn-wrap {
    margin: 0;
    gap: 50px;
    justify-content: center;

    @include bp(xlg-max) {
      gap: 40px;
    }

    @include bp(lg-max) {
      gap: 30px;
    }

    @include bp(sm-max) {
      gap: 20px;
    }

    .btn {
      max-width: 360px;
      min-width: 360px;
      margin: 0;

      @include bp(lg-max) {
        max-width: 300px;
        min-width: 300px;
      }

      @include bp(sm-max) {
        max-width: 280px;
        min-width: 280px;
      }

      &.border-btn {
        height: 58px !important;
        display: block;

        span {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 32px;
        }
      }
    }
  }
}

.chart-complaints {
  width: 100%;
  margin-bottom: 30px;

  @include bp(sm-max) {
    max-width: 100%;
  }

  .complaints-desc {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
  }

  .complaints-count {
    font-size: 150px;
    line-height: 1;
    color: $primary-color;
    font-family: $secondary-fonts !important;
    font-weight: 600;

    @include bp(md-max) {
      font-size: 100px;
    }

    @include bp(sm-max) {
      font-size: 70px;
    }

    &.plus-size {
      font-size: 15rem;
      text-align: center;
    }
  }

  .chart-complaints-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;
    padding-bottom: 30px;

    li {
      width: 50%;
      padding: 0 15px;
      text-align: center;
      margin-bottom: 30px;

      @include bp(sm-max) {
        width: 100%;
      }

      .complaints-desc {
        width: 100%;
        min-height: 50px;

        @include bp(sm-max) {
          min-height: unset;
        }
      }
    }
  }

  .total-complaints {
    text-align: center;

    .complaints-count {
      font-size: 60px;
      color: $red1;
      font-family: $primary-fonts !important;
      margin-top: 50px;

      @include bp(md-max) {
        font-size: 44px;
      }

      @include bp(sm-max) {
        font-size: 32px;
      }
    }
  }
}

// Survey js TOC CSS
.sv_progress-toc.sv_progress-toc--right {
  margin: 0 0 0 20px;
}

.sv_progress-toc.sv_progress-toc--left {
  margin: 0 20px 0 0;
}

.sv_progress-toc .sv-list__item.sv-list__item--selected .sv-list__item-body {
  background: $light-blue1 !important;
}

// Survey js TOC CSS
.client-file-review {
  .card-outline-inner {
    max-width: 600px;

    .client-name {
      margin-bottom: 1rem;
      margin-left: 2rem;
    }

    .btn {
      margin-left: 1rem;
    }

    .edit-container {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      // margin-left: 2rem;
      .form-control {
        flex: 1;
      }
    }

    .client-file-review-template {
      display: flex;
      align-items: center;

      .edit {
        &.template-inner-edit-btn {
          padding: 5px !important;
          border-radius: 50% !important;
          min-width: 40px;
          min-height: 40px;
        }
      }
    }
  }
}

.svc-flex-row.svc-full-container {
  height: calc(110vh - 161px) !important;
}

.chart-disclaimer-box {
  background-color: #1da2ef;
  color: #ffffff;
  margin: 2rem 0;
  border-radius: 16px;

  .chart-disclaimer {
    padding: 1rem;
  }
}

.frequency-disclaimer {
  color: #1da2ef;
  margin: 0.2rem 0;
}

.custom-table {
  width: 100%;
  border-spacing: 0;
}

.custom-table th {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  color: #667085;
}

.custom-table td {
  padding: 8px;
  color: #344054;
  font-size: 14px;
}

body .material-icons {
  font-family: 'Material Icons' !important;
}