.record-section {
    padding: 30px 0 50px; 
    .inner-text-wrap {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        padding: 0 20px;
        display: inline-block;
    }
    &.btm-pad-0{
        padding-bottom: 0;
    }
}
.inner-btn-wrap {
    display: flex;
    gap: 30px;
    &.inner-attestation {
        justify-content: space-between;
        flex: 1;
        .form-group {
            flex: none;
            max-width: none;
            @include bp(sm-max) {
                flex: 1 !important;
                max-width: 100% !important;
              }
        }
    }
    .btn {
        font-size: 14px !important;
        height: 40px !important;
    }
    .form-group {
        margin: 0;
    }
    &.secondary-button-wrap{
        padding-right: 25px;
        .btn-wrap {
            flex: 1;
            justify-content: center;
        } 
        .template-logo{
            max-width: 55px;
            width: 55px;
            display: inline-block;
        }
    }
    &.wrap-center{
        display: block;
        text-align: center;
        &.wrap-padding{
           padding: 20px;
        }
    }
    &.wrap-center-two{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.file-review-margin {
        margin-top: 10px;
    }
}
.row-wrap {
    .form-group{
        .inner-btn-wrap-complete {
            display: block;
            text-align: end;
        }
    }
}
.record-outer-row {
    display: flex;
    .record-left-nav{
        padding-right: 30px; 
        @include bp(tablet-max) {
          padding-right: 0;
        }
    }
    .right-outer {
        width: 100%; 
    }
}
.chart-outer {
    border: 1px solid $light-gray3;
    border-radius: 12px;
    .chart-title-wrapper {
        display: flex;
        border-bottom: 1px solid $light-gray3;
        .chart-left-title {
            flex: 0 0 62%;
            max-width: 62%;
            border-right: 1px solid $light-gray3;
            padding: 20px 24px;
        }
        .chart-right-title {
            flex: 0 0 38%;
            max-width: 38%;
            padding: 20px 24px;
        }
        .chart-full-title {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 20px 24px;
            border-top: 1px solid $light-gray3;
        }
        h4 {
            margin: 0 0 4px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
        }
    }
}
.chart-wrapper {
    padding: 30px;
    display: flex;
    margin: 0 -10px;
    .chart-left {
        flex: 0 0 62%;
        max-width: 62%;
        padding: 0 10px;
    }
    .chart-right {
        flex: 0 0 38%;
        max-width: 38%;
        padding: 0 10px;
    }
}
.date-picker {
    @include bp(md-max) {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            right: 15px;
            top: 13px;
            background: url('../assets/images/date.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 14px;
            height: 14px;
        }
    }
    button {
        padding: 0;
        &:before {
            content: '';
            position: absolute;
            right: 0;
            background: url('../assets/images/date.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 14px;
            height: 14px;
        }
        svg {
            display: none;
        }
    }
}
.card-outline {
    .sd-root-modern {
        padding: 0;
        background: transparent;
    }
    .sd-container-modern__title {
        padding: 0 !important; 
        box-shadow: none !important;
    }
    .sd-header__text {
        .sd-title {
            margin: 0 0 30px !important;            
            border-bottom: 1px solid $light-gray4;
            font-size: 20px;
            line-height: 1.5;
            font-weight: 600;
            color: $gray2; 
            padding: 0 0 23px;
            @include bp(md-max) {
                margin: 0 0 24px !important;
                padding: 0 0 20px;
            } 
        }
        .sd-description {
            display: none;
        }
    }
    .sv-components-row {
        .sv-components-column {
            padding: 0;
            max-width: 100%;
            .sd-body__page{
                padding: 0;
            }
            .sd-body.sd-body--static .sd-body__navigation.sd-action-bar{
                padding: 30px 0 0;
            }
        }
        .sd-body {
            max-width: 100%;
            padding: 0;
        }
    }
    .sd-row.sd-page__row {
        margin-top: 30px !important;
        &:first-child {
            margin-top: 0 !important;
        }
        @include bp(sm-max) {
            margin-top: 20px;
        }
        .sd-row__question {
            padding: 0;
            box-shadow: none;
        }
    }
    .sd-question__header {
        padding: 0;
        .sd-element__num {
            display: none;
        }
        .sv-string-viewer {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.42;
            color: $gray3;
            margin: 0 0 6px;
            display: inline-block;
        }
        .sd-description {
            .sv-string-viewer {
                color: $gray5;
                font-weight: 500;
            }
        }
    }
.sd-question__content {
        .sd-selectbase {
   .sv-dropdown_select-wrapper {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        .sd-input {
            padding: 10px 14px;
            background: $white;
            box-shadow: none;
            color: $gray1;
            letter-spacing: 0;
            font-size: 16px;
            line-height: 1.5;
            border: 1px solid $light-gray4;
            border-radius: 8px;
        }
        
        select::placeholder {
            color: red;
        }
        .sd-dropdown_clean-button{
            margin-right: 20px;
        }
      }
    }
  }
    .sd-comment__content {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        .sd-input {
            padding: 10px 14px;
            background: $white;
            box-shadow: none;
            color: $gray1;
            letter-spacing: 0;
            font-size: 16px;
            line-height: 1.5;
            border: 1px solid $light-gray4;
            border-radius: 8px;
        }
    }
    .sd-text__content {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        .sd-input {
            padding: 10px 14px;
            background: $white;
            box-shadow: none;
            color: $gray1;
            letter-spacing: 0;
            font-size: 16px;
            line-height: 1.5;
            border: 1px solid $light-gray4;
            border-radius: 8px;
        }
    }
}
.comp-uder-detail { 
    border-bottom: 1px solid $light-gray4;
    margin-bottom: 20px;
    .comp-uder-detail-list {
        margin: 0 0 16px;
        &:last-child {
            margin: 0 0 20px;
        }
        h6 {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: $gray3;
            margin: 0 0 6px;
        }
        p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $gray5;
        }
    }
}
.no-record {
    padding: 16px;
    text-align: center;
}
.no-record-finalise{
    font-weight: 400;
    // font-style: italic;
    font-size: 14px;
    line-height: 20px;
    // text-align: center;

}
.no-record-submitted{
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
    color:$light-gray5;

}
.no-record-adviser-incident{
    font-weight: 400;
    font-style: italic;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 10px;
    color:$light-gray5;

}
.record-create-form {
    margin: 0 0 30px;
}
.user-img-wrap {
    display: flex;
    align-items: center;
    .user-name {
        padding-left: 12px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $gray4;
        white-space: nowrap;
    }
}
.modal-outer {
    .MuiModal-backdrop {
        background-color: rgba(16,24,40, 0.58);
    }
    .MuiDialog-container {
        overflow-y: auto;
    }
    .MuiDialog-paper {
        max-width: 845px;
        width: 100%;
        border-radius: 16px;
        overflow: visible;
    }
    .modal-wrapper {
        padding: 40px;
        position: relative;
        border-radius: 16px;
        background-color: $white;
        @include bp(md-max) {
            padding: 25px;
        }
        @include bp(sm-max) {
            padding: 15px;
        }
        .modal-title {
            margin: 0 0 30px;
            border-bottom: 1px solid $light-gray4;
            @include bp(sm-max) {
                margin: 0 0 20px;
            }
            h2 {
                margin: 0 0 16px;
                font-weight: 700;
                color: $gray2;
                @include bp(sm-max) {
                    margin: 0 0 12px;
                }
            }
        }
    }
    .card-outline {
        padding: 0;
        border: 0;
    }
    .modal-close {
        position: absolute;
        top: -25px;
        right: -30px;
        min-width: 1px;
        color: $white;
        &:hover {
            background-color: transparent;
        }
    }
}