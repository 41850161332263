.user-profile-section {
  padding: 100px 0;
  @include bp(tablet-max) {
    padding: 60px 0;
  }
  @include bp(sm-max) {
    padding: 40px 0;
  }
  .section-title {
    margin: 0 0 40px;
    @include bp(tablet-max) {
      margin: 0 0 30px;
    }
  }
  p{
    margin-top: 15px;
  }
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  .custom-col {
    padding: 0 30px;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 10px;
    @include bp(sm-max) {
      margin: 0;
    }
  }
  &.has-two-col {
    .custom-col {
      flex: 0 0 50%;
      max-width: 50%;
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}
.form-block {
  border: 1px solid $light-gray4;
  border-radius: 16px;
  padding: 30px;
  margin: 0 0 30px;
  @include bp(sm-max) {
    padding: 15px;
  }
  .form-title {
    padding: 0 0 24px;
    border-bottom: 1px solid $light-gray4;
    margin: 0 0 24px;
    @include bp(sm-max) {
      padding: 0 0 15px;
      margin: 0 0 15px;
    }
  }
  .form-inner-wrap {
    max-width: 780px;
    margin-bottom: -30px;
    @include bp(sm-max) {
      margin-bottom: -15px;
    }
    &.width-sm {
      max-width: 400px;
      @include bp(sm-max) {
        max-width: 100%;
      }
    }
  }
}
.profile-image-block {
  display: flex;
  align-items: center;
  .image-block {
    border-radius: 100%;
    background-color: $light-gray6;
    height: 90px;
    width: 90px;
    overflow: hidden;
    @include bp(sm-max) {
      width: 70px;
      height: 70px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .MuiAvatar-root {
      height: 100%;
      width: 100%;
    }
  }
  .content-block {
    padding: 0 0 0 20px;
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include bp(sm-max) {
      padding: 0 0 0 12px;
    }
    .btn {
      margin: 0 0 16px;
      @include bp(sm-max) {
        margin: 0 0 10px;
      }
      .file-upload-input {
        position: absolute;
        opacity: 0;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin:0 -8px -16px;
  .btn {
    margin:0 8px 16px;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-left {
    justify-content: flex-start;
  }
  &.space-between{
    justify-content: space-between;
  }
}
