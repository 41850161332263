.header {
  padding: 20px 0;
  background-color: $light-gray2;
  border-bottom: 1px solid $light-gray3;
  position: sticky;
  z-index: 9;
  top: 0;
  @include bp(sm-max) {
    padding: 10px 0;
  }
  &.with-appbar {
    padding-top: 0;
  }

  &.signup {
    .inner-header {
      display: flex;
      align-items: center;
      justify-content: center;
      .flag-wrap {
        padding: 0;
        i {
          height: 70px;
        }
      }
    }
  }

  .inner-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-wrap {
      padding: 0 15px 0 0;
      @include bp(sm-max) {
        padding: 0 10px 0 0;
      }
      @include bp(xxsm-max) {
        padding: 0 5px 0 0;
      }
      a {
        display: block;
      }
      img {
        height: 40px;
        width: 200px;
        object-fit: cover;
        @include bp(xs-max) {
          width: 100px;
          height: 25px;
        }
      }
    }
    .right-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include bp(tablet-min) {
        flex: 1;
      }

      &.onboarding-user {
        @include bp(tablet-min) {
          flex: none;
        }
      }
    }
    .profile-inner-wrapper {
      display: flex;
      align-items: center;
    }
    .menu-wrap {
      @include bp(tablet-min) {
        display: flex;
        flex: 1;
        justify-content: center;
      }
      @include bp(tablet-max) {
        position: fixed;
        top: 0;
        right: -100%;
        width: 300px;
        bottom: 0;
        background-color: $white;
        box-shadow: 0px 7px 13px -2px $light-gray4;
        transition: all 0.5s;
        transform: translateX(100%);
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        padding-top: 60px;
        @include bp(sm-max) {
          padding-top: 50px;
        }
      }
      &.open-menu {
        @include bp(tablet-max) {
          transform: translateX(0);
          visibility: visible;
          opacity: 1;
          z-index: 99;
          right: 0;
        }
      }
      .menu-list {
        display: flex;
        align-items: center;
        @include bp(tablet-max) {
          max-height: 100%;
          overflow-y: auto;
          flex-direction: column;
          align-items: flex-start;
          padding: 15px 25px;
        }
        .transparent-btn {
          padding: 8px 16px !important;
          height: 40px !important;
          box-shadow: none !important;
          @include bp(lg-max) {
            padding: 8px 12px !important;
          }
          @include bp(lmd-max) {
            padding: 8px 10px !important;
          }
          @include bp(tablet-max) {
            width: 100%;
            height: 50px !important;
            justify-content: space-between;
            padding: 8px 0 !important;
          }
          &[aria-expanded='true'] {
            color: $primary-color !important;
            i {
              transform: rotate(180deg);
              svg {
                color: $primary-color !important;
              }
            }
          }
        }
        .menu-item {
          @include bp(tablet-max) {
            width: 100%;
            border-bottom: 1px solid $light-gray3;
          }
        }
      }
    }
    .setting-profile-wrap {
      padding: 0 0 0 25px;
      @include bp(lg-max) {
        padding: 0 0 0 15px;
      }
      @include bp(lmd-max) {
        padding: 0 0 0 10px;
      }
      @include bp(xxxs-max) {
        padding: 0 0 0 5px;
      }
      .setting-profile-list {
        display: flex;
        align-items: center;
        .setting-profile-item {
          padding: 0 5px;
          .icon-btn {
            @include bp(sm-max) {
              height: 35px;
              width: 35px;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
            .MuiAvatar-root {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
    .flag-wrap {
      padding: 0 0 0 25px;
      @include bp(lg-max) {
        padding: 0 0 0 15px;
      }
      @include bp(xxss-max) {
        padding: 0 0 0 5px;
      }
      i {
        display: flex;
        align-items: center;
        height: 40px;
        @include bp(sm-max) {
          height: 35px;
        }
        img {
          min-width: 45px;
          @include bp(xs-max) {
            min-width: 50px;
            max-width: 50px;
          }
        }
      }
    }
    .hamburget-ic {
      display: none;
      position: relative;
      z-index: 100;
      @include bp(tablet-max) {
        display: block;
        margin: 0 -5px 0 15px;
      }
      @include bp(xxss-max) {
        margin: 0 -10px 0 5px;
      }
      .hamburger-btn {
        padding: 5px;
        svg {
          width: 30px;
          height: 30px;
          color: $gray5 !important;
          transition: all 0.3s;
          @include bp(sm-max) {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
  .overlay {
    display: none;
    @include bp(tablet-max) {
      display: block;
    }
  }
}
.user-detail-item {
  background-color: transparent !important;
  cursor: unset;
  .user-detail-wrap {
    display: flex;
    align-items: center;
    .image-block {
      flex: 0 0 40px;
      max-width: 40px;
      position: relative;
      .status {
        position: absolute;
        height: 12px;
        width: 12px;
        bottom: 1px;
        right: -3px;
        background-color: $green2;
        border: 2px solid $white;
        border-radius: 20px;
      }
    }
    .content-block {
      flex: 0 0 calc(100% - 40px);
      max-width: calc(100% - 40px);
      padding-left: 12px;
      p {
        font-size: 14px;
        line-height: 1.4;
        color: $gray4 !important;
        font-weight: 400;
        &.name {
          font-weight: 600;
          color: $gray3 !important;
        }
      }
    }
  }
}
.user-detail-wrap {
  & > .image-block {
    & > img {
      border-radius: 50%;
      height: 40px;
      object-fit: cover;
      object-position: center;
    }
  }
}
