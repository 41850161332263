.pre-login-wrapper {
  height: 100vh;
  overflow: hidden;
  .pre-login-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    .left-col {
      flex: 0 0 43.5%;
      max-width: 43.5%;
      overflow-y: auto;
      max-height: 100vh;
      @include bp(tablet-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(sm-max) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .left-inner {
        padding: 40px 25px;
        display: flex;
        flex-direction: column;
        max-width: 415px;
        width: 100%;
        margin: 0 auto;
        &.large {
          max-width: 520px;
        }
        .logo-wrap {
          max-width: 100px;
          width: 100%;
          display: block;
          margin: 0 0 30px;
          @include bp(tablet-max) {
            margin: 0 0 15px;
            max-width: 100px;
          }
        }
        .title-wrap {
          margin: 0 0 30px;
          .session-expire{
            font-size: 30px;
            margin-bottom: 10px;
          }
          @include bp(tablet-max) {
            margin: 0 0 20px;
          }
          h1 {
            margin: 0 0 12px;
          }
          p {
            color: $gray4;
            &:not(:last-child) {
              margin: 0 0 12px;
            }
          }
        }
        .checkbox-link-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          .primary-link {
            margin: 0 0 20px;
          }
        }
        .btn {
          width: 100%;
        }
        .btn.logout-btn{
          display: block;
          margin: 50px auto;
          width: 50%;
          background-color:  $white !important;
          color: $primary-color !important;
          border-color: $primary-color !important;
          &:hover {
            background-color: $primary-color !important;
            color:  $white !important;
            border-color: $primary-color !important;
          }
        }
        .back-link {
          display: flex;
          align-items: center;
          margin: 0 0 16px;
          font-size: 14px;
          line-height: 1.42;
          font-weight: 600;
          color: $light-gray5;
          text-decoration: none !important;
          .MuiSvgIcon-root {
            width: 20px;
            height: 20px;
            margin: 0 6px 0 0;
            @include transition(all 0.3s linear);
          }
          &:hover {
            .MuiSvgIcon-root {
              transform: translateX(-3px);
            }
          }
        }
        .text-link-wrap {
          p {
            font-size: 14px;
            line-height: 1.42;
          }
        }
        .selection-btn-wrap {
          .selection-btn {
            border: 2px solid $light-gray4 !important;
            min-height: 70px;
            height: auto !important;
            border-radius: 12px !important;
            padding: 12px 16px !important;
            justify-content: flex-start;
            overflow: hidden;
            background-color: transparent !important;
            &:not(:last-child) {
              margin: 0 0 20px;
            }
            &:hover {
              border-color: $primary-color !important;
              .arrow {
                transform: translateX(3px);
              }
            }
            .MuiTouchRipple-root {
              .MuiTouchRipple-child {
                background-color: $light-blue2;
              }
            }
            .img-wrap {
              height: 40px;
              margin-right: 16px;
              flex: 1;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              font-weight: 600;
              line-height: 1.56;
              color: $gray1;
              img {
                width: 200px;
                object-fit: cover;
              }
            }
            .arrow {
              margin-left: auto;
              display: block;
              transition: all 0.3s;
              svg {
                color: $light-gray5;
                width: 16px;
                height: 16px;
              }
            }
            .MuiTouchRipple-root {
              border-radius: 0;
            }
          }
        }
      }
    }
    .left-col.col {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .right-image-col {
      flex: 0 0 56.5%;
      max-width: 56.5%;
      height: 100%;
      @include bp(tablet-max) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(sm-max) {
        display: none;
      }
      .right-inner {
        height: 100%;
        .img-block {
          height: 100%;
          img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 100%;
          }
        }
      }
    }
  }
}
.pre-password {
  height: 80vh;
  overflow: hidden;
  .password-success {
    display: grid;
    place-items: center;
    align-items: center;
    height: 100%;
    img {
      padding: 40px;
    }
    .title-wrap {
      max-width: 460px;
      p {
        max-width: 400px;
        padding: 10px 0 20px 0;
      }
    }
    .btn {
      width: 100%;
    }
  }
}
