.settings-page-wrap {
  height: 100%;
  padding: 30px 0 50px;
  &.print-content {
    padding: 5px 0 5px !important;
  }
  &.print-content-second {
    padding: 0px 0 50px !important;
  }
  .toggle-button {
    display: none;
    @include bp(tablet-max) {
      display: block;
      position: inherit;
      right: 0;
      top: 0;
      border-radius: 50%;
      background-color: $light-gray6;
      margin-bottom: 20px;
    }
    svg {
      display: block;
    }
  }
  .close-btn-wrap {
    display: none;
    @include bp(tablet-max) {
      display: flex;
      justify-content: flex-end;
      margin-right: 5px;
    }
    svg {
      display: block;
    }
  }
  .settings-outer-row {
    display: flex;
    @include bp(md-max) {
      flex-direction: column;
    }
    .left-col {
      @include bp(md-max) {
        margin-bottom: 20px;
        text-align: center;
      }
    }
    .application-submit-image {
      @include bp(md-max) {
        margin: 0 auto;
      }
    }
    .settings-sidebar-wrap {
      padding-right: 30px;

      @include bp(tablet-max) {
        padding-right: 0;
      }
      &.open {
        .overlay {
          @include bp(tablet-max) {
            visibility: visible;
            opacity: 1;
          }
        }
        .settings-sidebar {
          @include bp(tablet-max) {
            right: 0;
            z-index: 999;
          }
        }
      }
      .settings-sidebar {
        background-color: $light-gray;
        border: 1px solid $light-gray3;
        @include border-radius(12px);
        width: 255px;
        position: sticky;
        top: 100px;
        padding: 20px 0;
        @include bp(tablet-max) {
          position: fixed;
          right: -100%;
          top: 0;
          bottom: 0;
          z-index: -1;
          @include border-radius(0);
          @include transition(all 0.5s linear);
        }
        .sidebar-inner {
          padding: 0 16px;
          max-height: calc(100vh - 75px);
          overflow-y: auto;
        }
      }
      .menu-item-wrap {
        .menu-item {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          @include border-radius(8px);
          @include transition(all 0.3s);
          cursor: pointer;
          &:not(:last-child) {
            margin: 0 0 8px;
          }
          &.active {
            background-color: $light-blue2;
          }
          .icon {
            margin-right: 12px;
            display: block;
            width: 24px;
            height: 24px;
          }
          .icon-right {
            margin-left: 90px;
            svg {
              width: 24px;
              height: 24px;
              left: 612px;
              top: 605px;
              background: $light-gray3;
              border-radius: 4px;
              .text-count {
                font-weight: 500;
                border-radius: 4px;
                color: $light-gray5;
                font-size: 14px;
                align-items: center;
              }
            }
          }
          span {
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            color: $gray3;
            display: block;
          }
        }
      }
    }
    .right-outer {
      width: 100%;
      max-width: calc(100% - 285px);
      &.full-width {
        max-width: 100%;
      }
      .disabled-col {
        opacity: 0.5;
        pointer-events: none;
      }
      @include bp(tablet-max) {
        max-width: 100%;
      }
      .right-inner {
        height: 100%;
        .title-wrap {
          margin: 0 0 30px;
          border-bottom: 1px solid $light-gray3;
          min-height: 65px;
          .link {
            padding-bottom: 10px;
            a {
              color: $blue3;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
.breadcrumbs-title-wrap {
  .title-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px;
    border-bottom: 1px solid $light-gray3;
    min-height: 65px;
    position: relative;
    > * {
      margin-bottom: 20px;
    }
    @include bp(tablet-max) {
      padding-right: 50px;
    }
    @include bp(sm-max) {
      min-height: auto;
    }
    h2 {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
.breadcrumbs-wrap {
  margin: 0 0 40px;
  @include bp(tablet-max) {
    margin: 0 0 25px;
  }
  .MuiBreadcrumbs-li {
    display: flex;
    align-items: center;
    a {
      color: $gray4;
      text-decoration: none;
      font-size: 14px;
      line-height: 20px;
      display: block;
      font-weight: 400;
    }
    span {
      font-size: 14px;
      line-height: 20px;
      display: block;
      color: $breadcrumbLinks;
      font-weight: 400;
    }
    .MuiBreadcrumbs-separator {
      margin: 0 12px;
    }
  }
}

.settings-two-col-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px -30px;
  .settings-column {
    padding: 0 10px;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 30px;
    @include bp(sm-max) {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0 0 25px;
    }
    .setting-column-inner {
      box-shadow: 0px 1px 3px 0px #1018281a;
      border: 1px solid $light-gray3;
      @include border-radius(12px);
      .content-wrap {
        padding: 24px;
        @include bp(md-max) {
          padding: 18px;
        }
        .content {
          p {
            font-size: 14px;
            line-height: 20px;
            color: $gray4;
          }
        }
      }
      .settings-title-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0 24px;
        @include bp(md-max) {
          margin: 0 0 20px;
        }
        .left {
          &:not(:last-child) {
            .left-inner {
              padding-right: 20px;
            }
          }
          .left-inner {
            display: flex;
            align-items: center;

            .img-wrap {
              max-width: 48px;
              height: 48px;
              flex: 0 0 48px;
              margin-right: 12px;
            }
          }
        }
      }
    }
    .settings-link-wrap {
      border-top: 1px solid $light-gray3;
      margin-top: auto;
      .settings-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $primary-color;
        text-decoration: none;
        @include bp(md-max) {
          padding: 12px 18px;
        }
      }
    }
  }
}
.filter-outer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  &.filter-outer-update {
    margin-left: auto;
    margin-right: auto;
  }
  .filter-list {
    flex: 1;
    padding: 0 8px;
    &.search-box {
      flex: 1 !important;
    }
    @include bp(sm-max) {
      flex: 1 0 auto;
    }
    @include bp(xxs-max) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    &:first-child {
      flex: 0 0 auto;
      @include bp(sm-max) {
        flex: 0 0 auto;
      }
      @include bp(xxs-max) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px;
      }
    }
  }
  .form-group {
    margin: 0 0 30px;
    @include bp(sm-max) {
      margin: 0 0 20px;
    }
  }
}

.table-checkbox tr th:first-child,
.table-checkbox tr td:first-child {
  padding-right: 0;
  text-align: right;
}
.adduser-outer {
  .profile-image-block {
    margin: 0 0 30px;
  }
  .secondary-link {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.25;
    color: $red1;
  }
  .switch-wrap {
    padding-left: 10px;
    @include bp(xs-max) {
      padding-left: 0;
    }
  }
}
.switch-wrap {
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 10px;
    color: $gray4;
    display: inline-block;
  }
  .mute-text {
    color: $light-gray5;
  }
  &.switch-margin {
    margin-left: 10px;
    padding-bottom: 15px;
  }
}
.pagination {
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.Number {
  display: flex;
  gap: 2px;
  align-items: center;
  span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $gray4;
    cursor: pointer;
    transition:
      background-color 0.3s,
      color 0.3s;
    &.active,
    &:hover {
      background-color: $light-gray1;
      color: $gray2;
    }
  }
}
.previous-btn,
.next-btn {
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 16px;
  background-color: $white;
  color: $primary-color;
  cursor: pointer;
  font-weight: 600;
  border: 1px solid $light-gray4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  &:hover {
    // background-color: $gray1;
    background-color: $primary-color !important;
    // border-color: $gray1;
    color: $white !important;
  }
  &.disabled {
    background-color: $light-gray4;
    border-color: $light-gray4;
    color: $gray3;
    cursor: not-allowed;
    opacity: 0.7;
  }
}
.previous-btn {
  &.previous-align {
    margin-left: 68% !important;

    &.last-page {
      margin-left: 64% !important;
    }
  }
  svg {
    width: 20px;
    margin-right: 8px;
  }
}
.next-btn {
  svg {
    width: 20px;
    margin-left: 8px;
  }
}
.file-input-hide {
  display: none;
}

.file-adviser-title {
  margin-top: 20px;
}
.template-namefile-review {
  margin-left: 10px;
}
.border-top {
  margin-top: 40px !important;
  &.border-top-pdf {
    margin-top: 5px !important;
  }
}
