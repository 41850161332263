//Break points
@mixin bp($point) {
  $xxxs-max: '(max-width: 320px)';
  $xxxs-min: '(max-width: 359px)';
  $xxss-max: '(max-width: 375px)';
  $xxsm-max: '(max-width: 420px)';
  $xxs-max: '(max-width: 480px)';
  $xs-max: '(max-width: 575px)';
  $xs-min: '(min-width: 576px)';
  $sm-max: '(max-width: 767px)';
  $xsm-max: '(max-width: 800px)';
  $xsmd-max: '(max-width: 840px)';
  $md-min: '(min-width: 768px)';

  $xssmd-max: '(max-width: 950px)';
  $md-max: '(max-width: 991px)';
  $lg-min: '(min-width: 992px)';
  $tablet-max: '(max-width: 1024px)';
  $tablet-min: '(min-width: 1025px)';
  $lmd-max: '(max-width: 1100px)';
  $lg-max: '(max-width: 1199px)';
  $xlg-max: '(max-width: 1365px)';
  $xl-min: '(min-width: 1690px)';
  $xl-max: '(max-width: 1439px)';
  $xxl-max: '(max-width: 1499px)';
  $xxxl-md: '(max-width: 1600px)';
  $xxxl-max: '(max-width: 1680px)';
  $xxxl-min: '(max-width: 2200px)';

  @if $point==xxxs-max {
    @media #{$xxxs-max} {
      @content;
    }
  } @else if $point==xxxs-min {
    @media #{$xxxs-min} {
      @content;
    }
  } @else if $point==xxss-max {
    @media #{$xxss-max} {
      @content;
    }
  } @else if $point==xxsm-max {
    @media #{$xxsm-max} {
      @content;
    }
  } @else if $point==xxs-max {
    @media #{$xxs-max} {
      @content;
    }
  } @else if $point==xs-max {
    @media #{$xs-max} {
      @content;
    }
  } @else if $point==xs-min {
    @media #{$xs-min} {
      @content;
    }
  } @else if $point==sm-max {
    @media #{$sm-max} {
      @content;
    }
  } @else if $point==xsm-max {
    @media #{$xsm-max} {
      @content;
    }
  } @else if $point==xsmd-max {
    @media #{$xsmd-max} {
      @content;
    }
  } @else if $point==md-min {
    @media #{$md-min} {
      @content;
    }
  } @else if $point==xssmd-max {
    @media #{$xssmd-max} {
      @content;
    }
  } @else if $point==md-max {
    @media #{$md-max} {
      @content;
    }
  } @else if $point==lg-min {
    @media #{$lg-min} {
      @content;
    }
  } @else if $point==tablet-max {
    @media #{$tablet-max} {
      @content;
    }
  } @else if $point==tablet-min {
    @media #{$tablet-min} {
      @content;
    }
  } @else if $point==lg-max {
    @media #{$lg-max} {
      @content;
    }
  } @else if $point==lmd-max {
    @media #{$lmd-max} {
      @content;
    }
  } @else if $point==xlg-max {
    @media #{$xlg-max} {
      @content;
    }
  } @else if $point==xl-min {
    @media #{$xl-min} {
      @content;
    }
  } @else if $point==xl-max {
    @media #{$xl-max} {
      @content;
    }
  } @else if $point==xxl-max {
    @media #{$xxl-max} {
      @content;
    }
  } @else if $point==xxxl-md {
    @media #{$xxxl-md} {
      @content;
    }
  } @else if $point==xxxl-max {
    @media #{$xxxl-max} {
      @content;
    }
  } @else if $point==xxxl-min {
    @media #{$xxxl-min} {
      @content;
    }
  }
}
